@tailwind base;
@tailwind components;
@tailwind utilities;

body { 
  @apply text-gray-900;
}

.tw-container {
  @apply w-full max-w-screen-xl mx-auto px-4 md:px-6 lg:px-10
}

.tw-container-small {
  @apply w-full max-w-screen-lg mx-auto px-4 md:px-6 lg:px-10
}

.tw-padding {
  @apply pt-16 pb-6 lg:py-6;
}

h1, .h1 {
  @apply font-bold text-3xl lg:text-4xl;
}

h2, .h2 {
  @apply font-bold text-2xl lg:text-3xl;
}

h3, .h3 {
  @apply font-bold text-xl lg:text-2xl;
}

label {
  @apply block w-full mb-1.5;
}

input, textarea, select {
  @apply block w-full px-3 py-1 rounded border text-gray-800 placeholder-gray-500 focus:border-gray-900 focus:outline-none text-sm lg:text-base
}

a, button, input, textarea, select {
  @apply transition ease-in-out duration-300
}

input.topbarSearch {
  @apply bg-gray-800 text-white placeholder-gray-400 border-gray-700;
}

/* Tables */
.table {
  @apply w-full overflow-auto;
}

.table .hm {
  @apply hidden lg:block;
}

.table .hm-m {
  @apply hidden md:block;
}

.table .hm-l {
  @apply hidden xl:block;
}

.table .head, .table .row {
  @apply flex border-b border-gray-200 px-4 text-xs sm:text-sm;
}

.table .head div, .table .row div {
  @apply py-1 my-auto;
}

.table .head {
  @apply font-bold bg-gray-900 text-white rounded-t-md;
}

/* Buttons */
.button {
  @apply px-4 py-2 text-xs md:text-sm lg:text-base rounded-lg focus:outline-none;
}

.button.blue {
  @apply bg-blue-500 hover:bg-blue-700 text-white;
}

.button.teal {
  @apply bg-teal-600 hover:bg-teal-700 text-white;
}

.button.orange {
  @apply bg-orange-500 hover:bg-orange-700 text-white;
}

.button.red {
  @apply bg-red-500 hover:bg-red-700 text-white;
}

.button.default {
  @apply bg-gray-200 hover:bg-gray-300 text-gray-900 hover:opacity-90;
}